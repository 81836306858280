import React from "react";
import AJButtonGroup from "../../../../components/form/AJButtonGroup";
import { ScrollPanel } from "primereact/scrollpanel";

export default function BockRamschRunde({
  bockRamschRunde,
  setBockRamschRunde,
}) {
  const radios = [
    { name: "Normal", value: "" },
    { name: "Bockrunde", value: "bock" },
    { name: "Ramschrunde", value: "ramsch" },
  ];
  return (
    <>
      <div className="eingabe">
        <ScrollPanel className="custom" style={{ height: "43px", width: "100%" }}>
          <AJButtonGroup
            radios={radios}
            radioValue={bockRamschRunde}
            setRadioValue={setBockRamschRunde}
          />
        </ScrollPanel>
      </div>
    </>
  );
}
