import React from "react";
import AJButtonGroup from "../../../../components/form/AJButtonGroup";

export default function Buben({ mit, setMit, spitzen, setSpitzen, spiel }) {
  const radiosMit = [
    { name: "mit", value: "true" },
    { name: "ohne", value: "false" },
  ];
  const radiosSpitzen = [
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
  ];
  if(spiel !== "GRAND") {
    radiosSpitzen.push({name: "5", value: "5"})
    radiosSpitzen.push({name: "6", value: "6"})
  }
  return (
    <div className="eingabe">
      <h6 className="label">Buben:</h6>
      <AJButtonGroup
        radios={radiosMit}
        radioValue={mit}
        setRadioValue={setMit}
      />
      <AJButtonGroup
        radios={radiosSpitzen}
        radioValue={spitzen}
        setRadioValue={setSpitzen}
      />
    </div>
  );
}
