import React, { useEffect, useState, useContext } from "react";
import AJToggleButton from "../../../../components/form/AJToggleButton";
import AJTwoSelectButtonGroup from "../../../../components/form/AJTwoSelectButtonGroup";
import "./Ramsch.css";
import { ToastContext } from "../../../.././context/ToastContext";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

export default function Ramsch({
  spieler1,
  spieler2,
  spieler3,
  bockrunde,
  sendSpiel,
}) {
  const [valid, setValid] = useState(false);
  const { showError } = useContext(ToastContext);
  const [geschobenS1, setGeschobenS1] = useState(false);
  const [kontraS1, setKontraS1] = useState(false);
  const [jungfrauAngesagtS1, setJungfrauAngesagtS1] = useState(false);
  const [jungfrauGespieltS1, setJungfrauGespieltS1] = useState(false);
  const [durchmarschS1, setDurchmarschS1] = useState(false);
  const [geschobenS2, setGeschobenS2] = useState(false);
  const [kontraS2, setKontraS2] = useState(false);
  const [jungfrauAngesagtS2, setJungfrauAngesagtS2] = useState(false);
  const [jungfrauGespieltS2, setJungfrauGespieltS2] = useState(false);
  const [durchmarschS2, setDurchmarschS2] = useState(false);
  const [geschobenS3, setGeschobenS3] = useState(false);
  const [kontraS3, setKontraS3] = useState(false);
  const [jungfrauAngesagtS3, setJungfrauAngesagtS3] = useState(false);
  const [jungfrauGespieltS3, setJungfrauGespieltS3] = useState(false);
  const [durchmarschS3, setDurchmarschS3] = useState(false);
  const [verlierer, setVerlierer] = useState();
  const [verlierer2, setVerlierer2] = useState();
  const [punkte, setPunkte] = useState(0);
  const [anzahlGeschoben, setAnzahlGeschoben] = useState();
  const [anzahlKontra, setAnzahlKontra] = useState();
  const [anzahlJungfrauAngesagt, setAnzahlJungfrauAngesagt] = useState();
  const [jungfrauGespielt, setJungfrauGespielt] = useState();
  const [durchmarschGespielt, setDurchmarschGespielt] = useState(false);
  const radiosSpieler = [
    { name: spieler1, value: spieler1 },
    { name: spieler2, value: spieler2 },
    { name: spieler3, value: spieler3 },
  ];

  useEffect(() => {
    if (!durchmarschGespielt) return;
    setPunkte(120);
    setJungfrauGespieltS1(false);
    setJungfrauGespieltS2(false);
    setJungfrauGespieltS3(false);
  }, [
    durchmarschGespielt,
    jungfrauGespieltS1,
    jungfrauGespieltS2,
    jungfrauGespieltS3,
  ]);

  useEffect(() => {
    setDurchmarschGespielt(durchmarschS1 || durchmarschS2 || durchmarschS3);
  }, [durchmarschS1, durchmarschS2, durchmarschS3]);

  useEffect(() => {
    let aK = 0;
    if (kontraS1) aK += 1;
    if (kontraS2) aK += 1;
    if (kontraS3) aK += 1;
    setAnzahlKontra(aK);
  }, [kontraS1, kontraS2, kontraS3]);

  useEffect(() => {
    let aG = 0;
    if (geschobenS1) aG += 1;
    if (geschobenS2) aG += 1;
    if (geschobenS3) aG += 1;
    setAnzahlGeschoben(aG);
  }, [geschobenS1, geschobenS2, geschobenS3]);

  useEffect(() => {
    let aJA = 0;
    if (jungfrauAngesagtS1) aJA += 1;
    if (jungfrauAngesagtS2) aJA += 1;
    if (jungfrauAngesagtS3) aJA += 1;
    setAnzahlJungfrauAngesagt(aJA);
  }, [jungfrauAngesagtS1, jungfrauAngesagtS2, jungfrauAngesagtS1]);

  useEffect(() => {
    if (jungfrauGespieltS1 || jungfrauGespieltS2 || jungfrauGespieltS3) {
      setJungfrauGespielt(1);
    } else {
      setJungfrauGespielt(0);
    }
  }, [jungfrauGespieltS1, jungfrauGespieltS2, jungfrauGespieltS3]);

  useEffect(() => {
    setValid(verlierer !== undefined && punkte !== 0);
  }, [verlierer, punkte]);
  
  const onSubmit = () => {
    if (valid) {
      let jungfrauName;
      if (jungfrauGespieltS1) jungfrauName = spieler1;
      if (jungfrauGespieltS2) jungfrauName = spieler2;
      if (jungfrauGespieltS3) jungfrauName = spieler3;
      let ansage1 = "keine";
      if (jungfrauAngesagtS1) {
        ansage1 = "jungfrau";
      } else if (kontraS1) {
        ansage1 = "kontra";
      }
      let ansage2 = "keine";
      if (jungfrauAngesagtS2) {
        ansage2 = "jungfrau";
      } else if (kontraS2) {
        ansage2 = "kontra";
      }
      let ansage3 = "keine";
      if (jungfrauAngesagtS3) {
        ansage3 = "jungfrau";
      } else if (kontraS3) {
        ansage3 = "kontra";
      }
      sendSpiel({
        usp_hand1: geschobenS1,
        usp_hand2: geschobenS2,
        usp_hand3: geschobenS3,
        jungfrau_name: jungfrauName,
        ramsch_verlierer: verlierer,
        ramsch_verlierer2: verlierer2,
        ramsch_punkte: punkte,
        usp_ansage1: ansage1,
        usp_ansage2: ansage2,
        usp_ansage3: ansage3,
      });
    } else {
      showError("Die eingegeben Daten sind falsch!");
    }
  };
  return (
    <div className="eingabe">
      <RamschEingabe
        name={spieler1}
        geschoben={geschobenS1}
        setGeschoben={setGeschobenS1}
        kontra={kontraS1}
        setKontra={setKontraS1}
        jungfrauAngesagt={jungfrauAngesagtS1}
        setJungfrauAngesagt={setJungfrauAngesagtS1}
        jungfrauGespielt={jungfrauGespieltS1}
        setJungfrauGespielt={setJungfrauGespieltS1}
        durchmarsch={durchmarschS1}
        setDurchmarsch={setDurchmarschS1}
      />
      <RamschEingabe
        name={spieler2}
        geschoben={geschobenS2}
        setGeschoben={setGeschobenS2}
        kontra={kontraS2}
        setKontra={setKontraS2}
        jungfrauAngesagt={jungfrauAngesagtS2}
        setJungfrauAngesagt={setJungfrauAngesagtS2}
        jungfrauGespielt={jungfrauGespieltS2}
        setJungfrauGespielt={setJungfrauGespieltS2}
        durchmarsch={durchmarschS2}
        setDurchmarsch={setDurchmarschS2}
      />
      <RamschEingabe
        name={spieler3}
        geschoben={geschobenS3}
        setGeschoben={setGeschobenS3}
        kontra={kontraS3}
        setKontra={setKontraS3}
        jungfrauAngesagt={jungfrauAngesagtS3}
        setJungfrauAngesagt={setJungfrauAngesagtS3}
        jungfrauGespielt={jungfrauGespieltS3}
        setJungfrauGespielt={setJungfrauGespieltS3}
        durchmarsch={durchmarschS3}
        setDurchmarsch={setDurchmarschS3}
      />
      <hr />
      {!durchmarschGespielt && (
        <>
          <div className="eingabe">
            <h6>Verloren:</h6>
            <AJTwoSelectButtonGroup
              radios={radiosSpieler}
              radioValueOne={verlierer}
              radioValueTwo={verlierer2}
              setRadioValueOne={setVerlierer}
              setRadioValueTwo={setVerlierer2}
            />
          </div>
          <div className="eingabe">
            <Form.Control
              style={{ width: "60px", textAlign: "center" }}
              type="number"
              value={punkte}
              onChange={(e) => {
                const val = e.target.value;
                if (val > 120) {
                  setPunkte(120);
                } else if (val < 0) {
                  setPunkte(0);
                } else {
                  setPunkte(val);
                }
              }}
              onFocus={(e) => {
                e.target.select();
              }}
            />
          </div>
          <hr />
        </>
      )}
      <div className="eingabe">
        <table className="ramsch">
          <thead>
            <tr>
              <th>Geschoben</th>
              <th>Kontra</th>
              <th>Jungfrau angesagt</th>
              <th>Jungfrau gespielt</th>
              <th>Punkte</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{anzahlGeschoben}</td>
              <td>{anzahlKontra}</td>
              <td>{anzahlJungfrauAngesagt}</td>
              <td>{jungfrauGespielt}</td>
              <td>
                {punkte *
                  2 **
                    (anzahlGeschoben +
                      anzahlKontra +
                      anzahlJungfrauAngesagt +
                      jungfrauGespielt +
                      bockrunde)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button variant="dark" onClick={onSubmit} disabled={!valid}>
        hinzufügen
      </Button>
    </div>
  );
}

function RamschEingabe({
  name,
  geschoben,
  setGeschoben,
  kontra,
  setKontra,
  jungfrauAngesagt,
  setJungfrauAngesagt,
  jungfrauGespielt,
  setJungfrauGespielt,
  durchmarsch,
  setDurchmarsch,
}) {
  return (
    <div className="eingabe">
      <h6>{name}:</h6>
      <AJToggleButton
        value={geschoben}
        setValue={setGeschoben}
        name="Geschoben"
      />
      <AJToggleButton value={kontra} setValue={setKontra} name="Kontra" />
      <AJToggleButton
        value={jungfrauAngesagt}
        setValue={setJungfrauAngesagt}
        name="Jungfrau angesagt"
      />
      <AJToggleButton
        value={jungfrauGespielt}
        setValue={setJungfrauGespielt}
        name="Jungfrau gespielt"
      />
      <AJToggleButton
        value={durchmarsch}
        setValue={setDurchmarsch}
        name="Durchmarsch"
      />
    </div>
  );
}
