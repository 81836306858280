import React, { useState, useEffect, useContext } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./Sport.css";
import { VictoryAxis, VictoryChart, VictoryLine } from "victory";
import axios from "axios";
import { ApiContext } from "../../context/ApiContext";
import { AuthContext } from "../../context/AuthContext";
import { ToastContext } from "../../context/ToastContext";

function Sport() {
  const { amrapApi } = useContext(ApiContext);
  const { user } = useContext(AuthContext);
  const { showError, showSuccess } = useContext(ToastContext);

  const uebungen = [
    {
      id: "1",
      name: "Jumping Jacks",
    },
    {
      id: "2",
      name: "Squats",
    },
    {
      id: "3",
      name: "Burpees",
    },
    {
      id: "4",
      name: "Push Ups",
    },
    {
      id: "5",
      name: "Sumo Squats",
    },
    {
      id: "6",
      name: "Superman",
    },
    {
      id: "7",
      name: "30sec Plank",
    },
    {
      id: "8",
      name: "Lunges (jedes Bein 12x)",
    },
    {
      id: "9",
      name: "Hip Touches",
    },
    {
      id: "0",
      name: "Sit-Ups",
    },
  ];
  const [maxRunden, setMaxRunden] = useState(10);
  const [gesamtRunden, setGesamtRunden] = useState(0);
  const [anzahlTrainings, setAnzahlTrainings] = useState(0);
  const [datum, setDatum] = useState("1970-1-1");
  const [ganzeRunden, setGanzeRunden] = useState(0);
  const [geschaffteUebungen, setGeschaffteUebungen] = useState(0);
  const [trainings, setTrainings] = useState([]);
  
  const fetchUserData = () => {
    if (!user.usr_id) return;
    axios({
      method: "post",
      url: amrapApi() + "amrap_get_userdata.php",
      headers: { "content-type": "application/json" },
      data: {
        userId: user.usr_id,
      },
    })
      .then((result) => {
        if (result.data.success) {
          let trainings = result.data.message;

          if (trainings.length === 1) {
            trainings.push({
              arp_id: "0",
              arp_uebungen: "0",
              arp_datum: "1970-1-1",
            });
          }

          trainings = trainings
            .map((training) => {
              return {
                arp_id: Date.parse(training.arp_datum),
                arp_uebungen: parseInt(training.arp_uebungen),
                arp_datum: training.arp_datum,
              };
            })
            .sort((x1, x2) => x1.arp_id - x2.arp_id);
          // while(trainings.length > 5) {
          //     trainings.pop();
          // }
          setTrainings(trainings);
        } else {
          showError(result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const date = new Date();
    setDatum(
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [user]);

  useEffect(() => {
    const anzTrainings = trainings.length;
    if (anzTrainings > 0) {
      setMaxRunden(
        parseInt(
          trainings.reduce((x1, x2) => {
            if (parseInt(x2.arp_uebungen) > parseInt(x1.arp_uebungen)) {
              return x2;
            } else {
              return x1;
            }
          }).arp_uebungen
        )
      );
      let gesUebungen = 0;
      for (let i = 0; i < anzTrainings; i++) {
        gesUebungen += parseInt(trainings[i].arp_uebungen);
      }
      setGesamtRunden((gesUebungen / 10).toFixed(1));
      setAnzahlTrainings(anzTrainings);
    }
  }, [trainings]);

  function handleSubmit() {
    const data = {
      userId: user.usr_id,
      uebungen: parseInt(10 * ganzeRunden) + parseInt(geschaffteUebungen),
      datum: datum,
    };
    axios({
      method: "post",
      url: amrapApi() + "add_amrap.php",
      headers: { "content-type": "application/json" },
      data: data,
    })
      .then((result) => {
        console.log(result.data);
        showSuccess(result.data.message);
        fetchUserData();
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  return (
    <div className="content">
      <h1>Sport</h1>
      <br />
      <Accordion defaultActiveKey="2">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            AMRAP 15min
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <b>A</b>s <b>M</b>any <b>R</b>eps <b>A</b>s <b>P</b>ossible
              <p>
                Ziel ist es, möglichst viele Runden in 15 Minuten zu schaffen.
                Jede Übung wird zwölf Mal wiederholt:
              </p>
              {uebungen.map((uebung) => (
                <li key={uebung.id}>{uebung.name}</li>
              ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            Zusammenfassung
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <table className="sport">
                <thead>
                  <tr>
                    <th>Bezeichnung</th>
                    <th>Wert</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>User:</td>
                    <td>{user ? user.usr_name : ""}</td>
                  </tr>
                  <tr>
                    <td>Absolvierte Trainings:</td>
                    <td>{anzahlTrainings}</td>
                  </tr>
                  <tr>
                    <td>Gesamt Runden:</td>
                    <td>{gesamtRunden}</td>
                  </tr>
                  <tr>
                    <td>Durchschnittliche Runden pro Training:</td>
                    <td>{(gesamtRunden / anzahlTrainings).toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            Verlauf
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <VictoryChart domainPadding={5}>
                <VictoryAxis dependentAxis />
                <VictoryAxis style={{ tickLabels: { fontSize: "0px" } }} />
                <VictoryLine
                  style={{ data: { stroke: "#343a40" } }}
                  domain={{ y: [0, maxRunden] }}
                  animate={{
                    duration: 2000,
                    onLoad: { duration: 1000 },
                  }}
                  data={trainings}
                  x="arp_id"
                  y="arp_uebungen"
                />
              </VictoryChart>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            Hinzufügen
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <Form style={{ textAlign: "left" }}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Datum</Form.Label>
                  <Form.Control
                    type="date"
                    value={datum}
                    onChange={(e) => setDatum(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Geschaffte Runden</Form.Label>
                  <Row>
                    <Col>
                      <Form.Control
                        type="number"
                        placeholder="Ganze Runden"
                        min="0"
                        value={ganzeRunden}
                        onChange={(e) => setGanzeRunden(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="number"
                        placeholder="Übungen"
                        min="0"
                        max="9"
                        value={geschaffteUebungen}
                        onChange={(e) => setGeschaffteUebungen(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Form.Text className="text-muted">
                    Beispiel: Die Zeit ist während den Burpees in der fünften
                    Runde abgelaufen. Eingetragen wird dies als Ganze Runden = 4
                    und Übungen = 2. Es wurden vier Runden ganz geschafft und
                    zusätzlich 2 Übungen abgeschlossen. Es werden nur
                    abgeschlossene Übungen gezählt.
                  </Form.Text>
                </Form.Group>
                <Button
                  variant="outline-dark"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  Hinzufügen
                </Button>
              </Form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default Sport;
