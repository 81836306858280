import React, { useContext, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SkatContext } from "../../../context/SkatContext";
import AddSpiel from "../Spiel/AddSpiel";
import Button from "react-bootstrap/Button";
import { ApiContext } from "../../../context/ApiContext";
import axios from "axios";
import { ToastContext } from "../../../context/ToastContext";
import "./Scoreboard.css";
import ConfigAktiveSpieler from "./ConfigAktiveSpieler";
import Statistik from "../Statistik/Statistik";
// import "primeflex/primeflex.css";

function Scoreboard(props) {
  const AKTIVE_SPIELER = "aktiveSpieler-" + props.id;
  const { skatApi } = useContext(ApiContext);
  const { fetchSpiele, deleteGame } = useContext(SkatContext);
  const [scores, setScores] = useState([]);
  const [aktiveSpieler, setAktiveSpieler] = useState(
    JSON.parse(localStorage.getItem(AKTIVE_SPIELER)) || []
  );
  const [spieler, setSpieler] = useState([]);
  const [showAddSpiel, setShowAddSpiel] = useState(false);
  const [showCAS, setShowCAS] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const [punkteSystem] = useState("WOHNHEIM");
  const { showError } = useContext(ToastContext);

  useEffect(() => {
    if (aktiveSpieler) {
      localStorage.setItem(AKTIVE_SPIELER, JSON.stringify(aktiveSpieler));
    } else {
      localStorage.removeItem(AKTIVE_SPIELER);
    }
  }, [aktiveSpieler]);

  const fetchSpieler = () => {
    axios({
      method: "post",
      url: skatApi() + "scoreboard/get_players_for_scoreboard.php",
      headers: { "content-type": "application/json" },
      data: { scoreboardId: props.id },
    })
      .then((result) => {
        if (result.data.success) {
          const s = result.data.message.map((s) => s.usr_name);
          setSpieler(s);
          if (aktiveSpieler.length === 0) setAktiveSpieler(s);
        } else {
          console.log(result);
          showError(result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSpiele = async () => {
    const spiele = await fetchSpiele(props.id);
    let preScores = [];
    for (let i = 0; i < spiele.length; i++) {
      let doPush = true;
      for (let j = 0; j < preScores.length; j++) {
        if (spiele[i].spl_id === preScores[j].spl_id) {
          preScores[j][spiele[i].usr_name] = getPunkte(spiele[i]);
          doPush = false;
        }
      }
      if (doPush) {
        preScores.push({
          nr: spiele[i].nr,
          spl_id: spiele[i].spl_id,
          [spiele[i].usr_name]: getPunkte(spiele[i]),
        });
      }
    }
    setScores(preScores);
    await fetchSpieler();
  };

  useEffect(getSpiele, [props.id]);

  function getPunkte(spiel) {
    let punkte = NaN;
    switch (punkteSystem) {
      case "TURNIER":
        punkte = spiel.usp_turnier_punkte;
        break;
      case "WOHNHEIM":
        punkte = spiel.usp_wohnheim_punkte;
        break;
      case "EINFACH":
        punkte = getPunkteEinfach(spiel);
        break;
      default:
        break;
    }
    return punkte;
  }
  const getPunkteEinfach = (spiel) => {
    let multiplikator = parseInt(spiel.spl_spitzen) + 1;
    let grundwert = 0;
    switch (spiel.spl_gespielt) {
      case "KARO":
        grundwert = 9;
        break;
      case "HERZ":
        grundwert = 10;
        break;
      case "PIK":
        grundwert = 11;
        break;
      case "KREUZ":
        grundwert = 12;
        break;
      case "GRAND":
        grundwert = 24;
        break;
      default:
        grundwert = 0;
        break;
    }
    return grundwert * multiplikator * parseInt(spiel.usp_gewonnen);
  };
  const deleteG = (id) => {
    if (window.confirm("Spiel " + id + " wirklich löschen?")) {
      deleteGame(id).then(() => {
        window.alert("Spiel " + id + " wurde gelöscht");
        window.location.reload()
      });
    }
  };
  if (showStats) {
    return (
      <Statistik
        spieler={aktiveSpieler}
        scoreboard={props.id}
        close={() => setShowStats(false)}
      />
    );
  } else if (showCAS) {
    return (
      <ConfigAktiveSpieler
        spieler={spieler}
        aktiveSpieler={aktiveSpieler}
        setAktiveSpieler={setAktiveSpieler}
        close={() => setShowCAS(false)}
      />
    );
  } else if (showAddSpiel) {
    return (
      <AddSpiel
        scoreboardId={props.id}
        spieler={aktiveSpieler}
        close={async () => {
          await getSpiele();
          setShowAddSpiel(false);
        }}
      />
    );
  } else {
    return (
      <div className="scoreboard">
        <div className="config" onClick={() => setShowCAS(true)}>
          config
        </div>
        <div className="stats" onClick={() => setShowStats(true)}>
          stats
        </div>
        <h2>{props.name}</h2>
        <div>click on row to delete a game</div>
        <DataTable
          scrollable
          scrollHeight="79%"
          value={scores}
          className="p-datatable-gridlines p-datatable-striped"
          onRowClick={(e) => deleteG(parseInt(e.data.spl_id))}
        >
          <Column field="spl_id" header="Spiel-Nr."></Column>
          {aktiveSpieler.map((s, i) => (
            <Column key={i} field={s} header={s}></Column>
          ))}
        </DataTable>
        <Button
          variant="outline-dark"
          onClick={() => setShowAddSpiel(true)}
          size="lg"
          className="add-spiel-button"
        >
          Spiel hinzufügen
        </Button>
      </div>
    );
  }
}

export default Scoreboard;
