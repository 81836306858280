import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { ApiContext } from "./ApiContext";

export const AuthContext = createContext();

function AuthContextProvider(props) {
  const { authApi } = useContext(ApiContext);
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState({});

  const Axios = axios.create({
    baseURL: authApi(),
  });

  // On Click the Log out button
  const logoutUser = () => {
    localStorage.removeItem("loginToken");
    setIsAuth(false);
  }

  const registerUser = async (user) => {
        // Sending the user registration request
        const register = await Axios.post("register.php", {
          name: user.name,
          email: user.email,
          c_email: user.c_email,
          password: user.password,
          c_password: user.c_password,
        });
        return register.data;
      }

  const loginUser = async (user) => {
        // Sending the user Login request
        const login = await Axios.post("login.php", {
          email: user.email,
          password: user.password,
        });
        return login.data;
      }

  // Checking user logged in or not
  const isLoggedIn = async () => {
      const loginToken = localStorage.getItem("loginToken");
      // If inside the local-storage has the JWT token
      if (loginToken) {
        //Adding JWT token to axios default header
        Axios.defaults.headers.common["Authorization"] = "bearer " + loginToken;

        // Fetching the user information
        const {data} = await Axios.post("user-info.php", {
          token: loginToken,
        });
        // If user information is successfully received
        if (data.success && data.message) {
          setIsAuth(true);
          setUser(data.message);
        } else {
          setIsAuth(false);
          setUser();
        }
      } else {
        setIsAuth(false);
        setUser();
      }
    }

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuth: isAuth,
        user: user,
        isLoggedIn: isLoggedIn,
        registerUser: registerUser,
        loginUser: loginUser,
        logoutUser: logoutUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
