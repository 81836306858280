import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

export default function AJThreeSelectButtonGroup({
  style,
  radios,
  radioValueOne,
  setRadioValueOne,
  radioValueTwo,
  setRadioValueTwo,
  radioValueThree,
  setRadioValueThree,
}) {
  const setValue = (value) => {
    let newOne = radioValueOne;
    let newTwo = radioValueTwo;
    let newThree = radioValueThree;
    if (value === radioValueOne) {
      newOne = radioValueTwo;
      newTwo = radioValueThree;
      newThree = undefined;
    } else if (value === radioValueTwo) {
      newTwo = radioValueThree;
      newThree = undefined;
    } else if (value === radioValueThree) {
      newThree = undefined;
    } else if (radioValueOne === undefined) {
      newOne = value;
    } else if (radioValueTwo === undefined) {
      newTwo = value;
    } else if (radioValueThree === undefined) {
      newThree = value;
    } else {
      newOne = radioValueTwo;
      newTwo = radioValueThree;
      newThree = value;
    }
    setRadioValueOne(newOne);
    setRadioValueTwo(newTwo);
    setRadioValueThree(newThree);
  };
  return (
    <ButtonGroup toggle style={style}>
      {radios.map((radio, idx) => (
        <ToggleButton
          key={idx}
          type="radio"
          variant="dark"
          name="radio"
          value={radio.value}
          checked={
            radioValueOne === radio.value ||
            radioValueTwo === radio.value ||
            radioValueThree === radio.value
          }
          onClick={(e) => {
            e.target.value && setValue(e.target.value);
          }}
        >
          {radio.name}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
}
