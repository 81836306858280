import React, { createContext, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import { ApiContext } from "./ApiContext";
import { ToastContext } from "./ToastContext";

export const SkatContext = createContext();

function SkatContextProvider(props) {
  const { user } = useContext(AuthContext);
  const { skatApi } = useContext(ApiContext);
  const { showSuccess, showError } = useContext(ToastContext);

  async function fetch(api, data, doShowSuccess) {
    let scoreboards = [];
    if (user && user.usr_id) {
      scoreboards = await axios({
        method: "post",
        url: skatApi() + api,
        headers: { "content-type": "application/json" },
        data: data,
      })
        .then((result) => {
          if (result.data.success) {
            if (doShowSuccess) showSuccess(result.data.message);
            return result.data.message;
          } else {
            console.log(result);
            showError(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return scoreboards;
  }

  async function deleteGame(id) {
    return await fetch("spiel/delete_spiel.php", { spielId: id });
  }

  async function addScoreboard(id) {
    if (!user) return;
    return await fetch(
      "scoreboard/add_user_to_scoreboard.php",
      {
        userId: user.usr_id,
        scoreboardId: id,
      },
      true
    );
  }

  async function fetchScoreboards() {
    if (!user) return;
    return await fetch(
      "scoreboard/get_scoreboards_for_user.php",
      {
        userId: user.usr_id,
      },
      false
    );
  }

  async function fetchSpiele(scoreboard) {
    const spiele = (
      await fetch(
        "spiel/get_spiele_by_scoreboard.php",
        { scoreboardId: scoreboard },
        false
      )
    ).sort((s1, s2) => s1.spl_id - s2.spl_id);
    let spieleMitNummerAufScoreboard = [];
    for (let i = 0; i < spiele.length; i++) {
      let spiel = spiele[i];
      spiel.nr = (i + 1) / 3;
      spieleMitNummerAufScoreboard.push(spiel);
    }
    return spieleMitNummerAufScoreboard.reverse();
  }

  return (
    <SkatContext.Provider
      value={{
        fetchScoreboards: fetchScoreboards,
        addScoreboard: addScoreboard,
        fetchSpiele: fetchSpiele,
        deleteGame: deleteGame,
      }}
    >
      {props.children}
    </SkatContext.Provider>
  );
}

export default SkatContextProvider;
