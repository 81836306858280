import React, { useContext, useState, useEffect } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import Startseite from "./pages/Startseite";
import Sport from "./pages/sport/Sport";
import Login from "./pages/usercontrolls/Login";
import Registrieren from "./pages/usercontrolls/Registrieren";
import NotFound from "./pages/NotFound";
import PhaseZehn from "./pages/PhaseZehn/Phase10";
import Programme from "./pages/Programme/Programme";
import axios from "axios";
import { ApiContext } from "./context/ApiContext";
import { ToastContext } from "./context/ToastContext";
import Skat from "./pages/Skat/Skat";

function Home() {
  const { isAuth, logoutUser, isLoggedIn } = useContext(AuthContext);
  const { programmeApi } = useContext(ApiContext);
  const { user } = useContext(AuthContext);
  const [programme, setProgramme] = useState([]);
  const { showError } = useContext(ToastContext);

  function logout() {
    logoutUser();
    window.location = "/";
  }

  const fetchProgramme = () => {
    async function asyncFunction() {
      axios({
        method: "post",
        url: programmeApi() + "get_programme_of_user.php",
        headers: { "content-type": "application/json" },
        data: {
          userId: user.usr_id,
        },
      })
        .then((result) => {
          if (result.data.success) {
            setProgramme(result.data.message);
          } else {
            showError(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    asyncFunction();
  };

  useEffect(() => isLoggedIn(), [])

  useEffect(() => {
    if (user && user.usr_id) fetchProgramme();
  }, [user]);

  if (isAuth) {
    //LoggedIn
    return (
      <Router>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" style={{zIndex: "10000"}}>
          <Navbar.Brand href="/">andreasjuergensen.de</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/">Startseite</Nav.Link>
              {programme.map((p) => (
                <Nav.Link key={p.prm_id} href={p.prm_link}>
                  {p.prm_name}
                </Nav.Link>
              ))}
              <Nav.Link href="/programme">Programme</Nav.Link>
              <Button variant="dark" onClick={logout}>
                Logout
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Switch>
          <Route exact path="/">
            <Startseite />
            <Programme fetchP={fetchProgramme} />
          </Route>
          <Route path="/sport">
            <Sport />
          </Route>
          <Route path="/phasezehn">
            <PhaseZehn />
          </Route>
          <Route path="/programme">
            <Programme fetchP={fetchProgramme} />
          </Route>
          <Route path="/skat">
            <Skat />
          </Route>
          <Route path="/">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    );
  } else {
    //Not LoggedIn
    return (
      <Router>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Navbar.Brand href="/">andreasjuergensen.de</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/">Startseite</Nav.Link>
              <Nav.Link href="/login">Login</Nav.Link>
              <Nav.Link href="/registrieren">Registrieren</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Switch>
          <Route exact path="/" component={Startseite} />
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/registrieren">
            <Registrieren />
          </Route>
          <Route path="/">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default Home;
