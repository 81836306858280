import React from "react";
import AJButtonGroup from "../../../../components/form/AJButtonGroup";
import AJToggleButton from "../../../../components/form/AJToggleButton";

export default function Gewonnen({
  mitSechzigVerloren,
  setMitSechzigVerloren,
  gewonnen,
  setGewonnen,
  nullGespielt
}) {
  const radios = [
    { name: "Gewonnen", value: "true" },
    { name: "Verloren", value: "false" },
  ];
  const sechzig =
    (gewonnen === "false" && !nullGespielt) ? (
      <AJToggleButton
        value={mitSechzigVerloren}
        setValue={setMitSechzigVerloren}
        name="Mit 60 verloren"
      />
    ) : (
      <div />
    );
  return (
    <div className="eingabe">
      <AJButtonGroup
        radios={radios}
        radioValue={gewonnen}
        setRadioValue={setGewonnen}
      />
      {sechzig}
    </div>
  );
}
