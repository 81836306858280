import React, { createContext, useState } from "react";
import Toast from "react-bootstrap/Toast";

export const ToastContext = createContext();

function ToastContextProvider(props) {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("Bootstrap");
  const [message, setMessage] = useState(
    "Hello, world! This is a toast message."
  );

  const showToast = (title, message) => {
    setShow(true);
    setTitle(title);
    setMessage(message);
  }

  const showError = (message) => {
    showToast("Fehler!", message);
  }

  function showSuccess(message) {
    showToast("Erfolgreich!", message);
  }

  const toast = show ? (
    <Toast
      style={{
        position: "absolute",
        top: "2%",
        right: "2%",
        zIndex: 2000,
      }}
      delay={3000}
      autohide
      onClose={() => setShow(false)}
    >
      <Toast.Header>
        <strong className="mr-auto">{title}</strong>
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  ) : (
    <div />
  );

  return (
    <ToastContext.Provider
      value={{
        showToast: showToast,
        showError: showError,
        showSuccess: showSuccess,
      }}
    >
      {toast}
      {props.children}
    </ToastContext.Provider>
  );
}

export default ToastContextProvider;
