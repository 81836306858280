import React, { useState } from "react";
import "./Form.css";

function Form(props) {
  const [formData, setFormData] = useState({});

  function handleSubmit(event) {
    event.preventDefault();
    props.handleSubmit(formData);
  }

  const autoComplete = props.autoCompleteOff ? "new-password" : "";
  console.log(autoComplete)
  return (
    <form>
      {props.fields.map((field) => {
        return (
          <div key={field.id}>
            <label>{field.label}</label>
            <br />
            <input
              autocomplete={autoComplete}
              type={field.type}
              value={formData[field.name]}
              maxLength="250"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  [field.name]: e.target.value,
                });
              }}
            />
            <br />
            <br />
          </div>
        );
      })}
      <input type="submit" onClick={(e) => handleSubmit(e)} />
    </form>
  );
}

export default Form;
