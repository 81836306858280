import React, { useState, useEffect, useContext } from "react";
import "./AddSpiel.css";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "react-bootstrap";
import Spitzen from "./Eingabe/Spitzen";
import BockRamschRunde from "./Eingabe/BockRamschRunde";
import Gewonnen from "./Eingabe/Gewonnen";
import Anmerkung from "./Eingabe/Anmerkung";
import Buben from "./Eingabe/Buben";
import Ansagen from "./Eingabe/Ansagen";
import Gespielt from "./Eingabe/Gespielt";
import Spiel from "./Eingabe/Spiel";
import Spieler from "./Eingabe/Spieler";
import Punkte from "./Eingabe/Punkte";
import PunkteNull from "./Eingabe/PunkteNull";
import Ramsch from "./Eingabe/Ramsch";
import axios from "axios";
import { ApiContext } from "../../../context/ApiContext";
import { ToastContext } from "../../../context/ToastContext";
import Form from "react-bootstrap/Form";

function AddSpiel({ close, spieler, scoreboardId }) {
  const [bockRamschRunde, setBockRamschRunde] = useState("");
  const [spieler1, setSpieler1] = useState();
  const [spieler2, setSpieler2] = useState();
  const [spieler3, setSpieler3] = useState();
  const [mit, setMit] = useState();
  const [buben, setBuben] = useState();
  const [spiel, setSpiel] = useState();
  const [ansageSpieler1, setAnsageSpieler1] = useState("keine");
  const [ansageSpieler2, setAnsageSpieler2] = useState("keine");
  const [ansageSpieler3, setAnsageSpieler3] = useState("keine");
  const [hand, setHand] = useState();
  const [schneiderAngesagt, setSchneiderAngesagt] = useState();
  const [schneiderGespielt, setSchneiderGespielt] = useState();
  const [schwarzAngesagt, setSchwarzAngesagt] = useState();
  const [schwarzGespielt, setSchwarzGespielt] = useState();
  const [ouvert, setOuvert] = useState();
  const [revolution, setRevolution] = useState();
  const [spitzenAngesagt, setSpitzenAngesagt] = useState("");
  const [spitzenGespielt, setSpitzenGespielt] = useState("");
  const [gewonnen, setGewonnen] = useState("true");
  const [anmerkung, setAnmerkung] = useState("");
  const [mitSechzigVerloren, setMitSechzigVerloren] = useState();
  const [punkte, setPunkte] = useState();
  const [reizwert, setReizwert] = useState();
  const { skatApi } = useContext(ApiContext);
  const { showSuccess, showError } = useContext(ToastContext);

  const sendReiz = async () => {
    await send({
      spl_bockrunde: "" + (bockRamschRunde === "bock"),
      spl_ramschrunde: "" + (bockRamschRunde === "ramsch"),
      spl_mit: undefined,
      spl_spitzen: undefined,
      spl_anmerkung: undefined,
      usp_gewonnen: undefined,
      usp_hand1: undefined,
      usp_hand2: undefined,
      usp_hand3: undefined,
      usp_ouvert: undefined,
      usp_revolution: undefined,
      jungfrau_name: undefined,
      durchmarsch_name: undefined,
      usp_schneider_angesagt: undefined,
      usp_schneider_gespielt: undefined,
      usp_schwarz_angesagt: undefined,
      usp_schwarz_gespielt: undefined,
      usp_spitzen_angesagt: undefined,
      usp_spitzen_gespielt: undefined,
      usp_ansage1: ansageSpieler1,
      usp_ansage2: ansageSpieler2,
      usp_ansage3: ansageSpieler3,
      ramsch_verlierer: undefined,
      ramsch_verlierer2: undefined,
      ramsch_punkte: undefined,
      reizwert: reizwert,
    });
  };

  const sendRamsch = async ({
    usp_hand1,
    usp_hand2,
    usp_hand3,
    jungfrau_name,
    ramsch_verlierer,
    ramsch_verlierer2,
    ramsch_punkte,
    usp_ansage1,
    usp_ansage2,
    usp_ansage3,
    durchmarsch_name,
  }) => {
    await send({
      spl_bockrunde: "" + (bockRamschRunde === "bock"),
      spl_ramschrunde: "" + (bockRamschRunde === "ramsch"),
      spl_mit: undefined,
      spl_spitzen: undefined,
      spl_anmerkung: undefined,
      usp_gewonnen: undefined,
      usp_hand1: "" + usp_hand1,
      usp_hand2: "" + usp_hand2,
      usp_hand3: "" + usp_hand3,
      usp_ouvert: undefined,
      usp_revolution: undefined,
      jungfrau_name: jungfrau_name,
      durchmarsch_name: durchmarsch_name,
      usp_schneider_angesagt: undefined,
      usp_schneider_gespielt: undefined,
      usp_schwarz_angesagt: undefined,
      usp_schwarz_gespielt: undefined,
      usp_spitzen_angesagt: undefined,
      usp_spitzen_gespielt: undefined,
      usp_ansage1: usp_ansage1,
      usp_ansage2: usp_ansage2,
      usp_ansage3: usp_ansage3,
      ramsch_verlierer: ramsch_verlierer,
      ramsch_verlierer2: ramsch_verlierer2,
      ramsch_punkte: ramsch_punkte,
      reizwert: undefined,
    });
  };

  const sendNull = async () => {
    await send({
      spl_bockrunde: "" + (bockRamschRunde === "bock"),
      spl_ramschrunde: "false",
      spl_mit: undefined,
      spl_spitzen: undefined,
      spl_anmerkung: anmerkung,
      usp_gewonnen: "" + gewonnen,
      usp_hand1: "" + hand,
      usp_hand2: undefined,
      usp_hand3: undefined,
      usp_ouvert: ouvert,
      usp_revolution: revolution,
      jungfrau_name: undefined,
      durchmarsch_name: undefined,
      usp_schneider_angesagt: undefined,
      usp_schneider_gespielt: undefined,
      usp_schwarz_angesagt: undefined,
      usp_schwarz_gespielt: undefined,
      usp_spitzen_angesagt: undefined,
      usp_spitzen_gespielt: undefined,
      usp_ansage1: ansageSpieler1,
      usp_ansage2: ansageSpieler2,
      usp_ansage3: ansageSpieler3,
      ramsch_verlierer: undefined,
      ramsch_verlierer2: undefined,
      ramsch_punkte: undefined,
      reizwert: undefined,
    });
  };

  const sendSpiel = async () => {
    await send({
      spl_bockrunde: "" + (bockRamschRunde === "bock"),
      spl_ramschrunde: "" + (bockRamschRunde === "ramsch"),
      spl_mit: "" + mit,
      spl_spitzen: buben,
      spl_anmerkung: anmerkung,
      usp_gewonnen: gewonnen,
      usp_hand1: "" + hand,
      usp_hand2: undefined,
      usp_hand3: undefined,
      usp_ouvert: ouvert,
      usp_revolution: undefined,
      jungfrau_name: undefined,
      durchmarsch_name: undefined,
      usp_schneider_angesagt: "" + schneiderAngesagt,
      usp_schneider_gespielt: "" + schneiderGespielt,
      usp_schwarz_angesagt: "" + schwarzAngesagt,
      usp_schwarz_gespielt: "" + schwarzGespielt,
      usp_spitzen_angesagt: spitzenGespielt,
      usp_spitzen_gespielt: spitzenAngesagt,
      usp_ansage1: ansageSpieler1,
      usp_ansage2: ansageSpieler2,
      usp_ansage3: ansageSpieler3,
      ramsch_verlierer: undefined,
      ramsch_verlierer2: undefined,
      ramsch_punkte: undefined,
      reizwert: undefined,
    });
  };

  const send = async ({
    spl_bockrunde,
    spl_ramschrunde,
    spl_mit,
    spl_spitzen,
    spl_anmerkung,
    usp_gewonnen,
    usp_hand1,
    usp_hand2,
    usp_hand3,
    usp_ouvert,
    usp_revolution,
    jungfrau_name,
    durchmarsch_name,
    usp_schneider_angesagt,
    usp_schneider_gespielt,
    usp_schwarz_angesagt,
    usp_schwarz_gespielt,
    usp_spitzen_angesagt,
    usp_spitzen_gespielt,
    usp_ansage1,
    usp_ansage2,
    usp_ansage3,
    ramsch_verlierer,
    ramsch_verlierer2,
    ramsch_punkte,
    reizwert,
  }) => {
    const send = {
      spl_scoreboardId: scoreboardId,
      spl_gespielt: spiel,
      spl_bockrunde: "" + spl_bockrunde,
      spl_ramschrunde: "" + spl_ramschrunde,
      spl_mit: "" + spl_mit,
      spl_spitzen: spl_spitzen,
      spl_anmerkung: spl_anmerkung,
      usp_user1: spieler1,
      usp_user2: spieler2,
      usp_user3: spieler3,
      usp_gewonnen: usp_gewonnen,
      usp_hand1: "" + usp_hand1,
      usp_hand2: "" + usp_hand2,
      usp_hand3: "" + usp_hand3,
      usp_ouvert: "" + usp_ouvert,
      usp_revolution: "" + usp_revolution,
      jungfrau_name: jungfrau_name,
      durchmarsch_name: durchmarsch_name,
      usp_schneider_angesagt: usp_schneider_angesagt,
      usp_schneider_gespielt: usp_schneider_gespielt,
      usp_schwarz_angesagt: "" + usp_schwarz_angesagt,
      usp_schwarz_gespielt: "" + usp_schwarz_gespielt,
      usp_spitzen_angesagt: "" + usp_spitzen_angesagt,
      usp_spitzen_gespielt: "" + usp_spitzen_gespielt,
      usp_ansage1: usp_ansage1,
      usp_ansage2: usp_ansage2,
      usp_ansage3: usp_ansage3,
      ramsch_verlierer: ramsch_verlierer,
      ramsch_verlierer2: ramsch_verlierer2,
      ramsch_punkte: ramsch_punkte,
      reizwert: reizwert,
    };
    console.log("🚀 ~ file: AddSpiel.js ~ line 247 ~ AddSpiel ~ send", send);
    const { data } = await axios.post(skatApi() + "spiel/add_spiel.php", send);
    if (data.success) {
      showSuccess(data.message);
    } else {
      showError(data.message);
      console.log(data);
    }
    close();
  };

  //bei Ramsch Grand nur als Hand
  useEffect(() => {
    if (bockRamschRunde === "ramsch" && spiel === "GRAND") setHand(true);
  }, [spiel, hand]);

  //wenn Ramschrunde und Spiel ist nicht Grand Hand dann Ramsch
  useEffect(() => {
    if (bockRamschRunde === "ramsch" && (spiel !== "GRAND" || !hand))
      setSpiel("RAMSCH");
  }, [bockRamschRunde]);

  //wenn revolution dann auch hand und ouvert
  useEffect(() => {
    if (revolution) {
      setHand(true);
      setOuvert(true);
    }
  }, [revolution, hand, ouvert]);

  //wenn spiel wechsel dann spieler resetten
  useEffect(() => {
    setSpieler1();
    setSpieler2();
    setSpieler3();
  }, [spiel]);

  useEffect(() => {
    if (bockRamschRunde === "") {
      if (gewonnen === "false" && schneiderAngesagt) {
        setAnmerkung("Schneider angesagt und verloren");
      } else if (mitSechzigVerloren) {
        setAnmerkung("Mit 60 Punkten verloren");
      } else if (punkte >= 200) {
        setAnmerkung("Spiel mit mindestens 200 Punkten");
      } else if (spiel === "GRAND" && hand) {
        setAnmerkung("Grand Hand gespielt");
      } else if (
        (gewonnen === "false" && schneiderGespielt) ||
        schwarzGespielt
      ) {
        setAnmerkung("Spieler wurde selbst Schneider gespielt");
      } else if (revolution) {
        setAnmerkung("Null Hand Ouvert Revolution");
      } else {
        setAnmerkung();
      }
    } else {
      setAnmerkung()
    }
  }, [
    gewonnen,
    schneiderAngesagt,
    schwarzAngesagt,
    mitSechzigVerloren,
    punkte,
    spiel,
    hand,
    schneiderGespielt,
    schwarzGespielt,
    revolution,
    bockRamschRunde
  ]);

  return (
    <div className="addSpiel">
      <div style={{ position: "relative", height: "100%" }}>
        <Button
          variant="light"
          style={{ position: "absolute", top: "-8px", right: "-8px" }}
          onClick={close}
        >
          X
        </Button>
        <h5>Neues Spiel hinzufügen</h5>
        <div
          style={{
            position: "absolute",
            top: "30px",
            left: "0px",
            bottom: "0px",
            right: "0px",
          }}
        >
          <ScrollPanel style={{ width: "100%", height: "100%" }}>
            <div>
              <BockRamschRunde
                bockRamschRunde={bockRamschRunde}
                setBockRamschRunde={setBockRamschRunde}
              />

              <>
                <hr />
                <Spiel spiel={spiel} setSpiel={setSpiel} />
                {spiel && (
                  <>
                    <hr />
                    <Spieler
                      spieler1={spieler1}
                      setSpieler1={setSpieler1}
                      spieler2={spieler2}
                      setSpieler2={setSpieler2}
                      spieler3={spieler3}
                      setSpieler3={setSpieler3}
                      spieler={spieler}
                      ramsch={spiel === "RAMSCH"}
                    />
                  </>
                )}
                {spieler1 != undefined &&
                  spieler2 != undefined &&
                  spieler3 != undefined && (
                    <>
                      {spiel === "REIZ" ? (
                        <>
                          <hr />
                          <div className="eingabe">
                            <h6 className="label">Reizwert:</h6>
                            <Form.Control
                              style={{
                                width: "120px",
                                textAlign: "center",
                              }}
                              type="number"
                              value={reizwert}
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val < 0) {
                                  setReizwert(0);
                                } else {
                                  setReizwert(val);
                                }
                              }}
                              onFocus={(e) => {
                                e.target.select();
                              }}
                            />
                            <Button variant="dark" onClick={() => sendReiz()}>
                              hinzufügen
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          {spiel !== "RAMSCH" && (
                            <>
                              {spiel !== "NULL" && (
                                <>
                                  <Buben
                                    mit={mit}
                                    setMit={setMit}
                                    spitzen={buben}
                                    setSpitzen={setBuben}
                                    spiel={spiel}
                                  />
                                </>
                              )}
                              <Gespielt
                                hand={hand}
                                setHand={setHand}
                                schneiderAngesagt={schneiderAngesagt}
                                setSchneiderAngesagt={setSchneiderAngesagt}
                                schneiderGespielt={schneiderGespielt}
                                setSchneiderGespielt={setSchneiderGespielt}
                                schwarzAngesagt={schwarzAngesagt}
                                setSchwarzAngesagt={setSchwarzAngesagt}
                                schwarzGespielt={schwarzGespielt}
                                setSchwarzGespielt={setSchwarzGespielt}
                                ouvert={ouvert}
                                setOuvert={setOuvert}
                                revolution={revolution}
                                setRevolution={setRevolution}
                                nullGespielt={spiel === "NULL"}
                              />
                              {spiel !== "NULL" && (
                                <>
                                  <hr />
                                  <Spitzen
                                    spitzenAngesagt={spitzenAngesagt}
                                    setSpitzenAngesagt={setSpitzenAngesagt}
                                    spitzenGespielt={spitzenGespielt}
                                    setSpitzenGespielt={setSpitzenGespielt}
                                    grandGespielt={spiel === "GRAND"}
                                  />
                                  <hr />
                                  <Ansagen
                                    einzelspieler={spieler1}
                                    ansageEinzelspieler={ansageSpieler1}
                                    setAnsageEinzelspieler={setAnsageSpieler1}
                                    gegenspieler1={spieler2}
                                    ansageGegenspieler1={ansageSpieler2}
                                    setAnsageGegenspieler1={setAnsageSpieler2}
                                    gegenspieler2={spieler3}
                                    ansageGegenspieler2={ansageSpieler3}
                                    setAnsageGegenspieler2={setAnsageSpieler3}
                                  />
                                </>
                              )}
                              <hr />
                              <Gewonnen
                                mitSechzigVerloren={mitSechzigVerloren}
                                setMitSechzigVerloren={setMitSechzigVerloren}
                                gewonnen={gewonnen}
                                setGewonnen={setGewonnen}
                                setHand={setHand}
                                schneiderAngesagt={schneiderAngesagt}
                                setSchneiderAngesagt={setSchneiderAngesagt}
                                schneiderGespielt={schneiderGespielt}
                                setSchneiderGespielt={setSchneiderGespielt}
                                schwarzAngesagt={schwarzAngesagt}
                                setSchwarzAngesagt={setSchwarzAngesagt}
                                schwarzGespielt={schwarzGespielt}
                                setSchwarzGespielt={setSchwarzGespielt}
                                ouvert={ouvert}
                                setOuvert={setOuvert}
                                revolution={revolution}
                                setRevolution={setRevolution}
                                nullGespielt={spiel === "NULL"}
                              />
                              {spiel !== "NULL" &&
                                buben !== undefined &&
                                gewonnen !== undefined && (
                                  <>
                                    <Punkte
                                      sendSpiel={sendSpiel}
                                      punkte={punkte}
                                      setPunkte={setPunkte}
                                      mit={mit === "true"}
                                      buben={buben}
                                      hand={hand}
                                      schneiderAngesagt={schneiderAngesagt}
                                      schneiderGespielt={schneiderGespielt}
                                      schwarzAngesagt={schwarzAngesagt}
                                      schwarzGespielt={schwarzGespielt}
                                      ouvert={ouvert}
                                      spitzenAngesagt={spitzenAngesagt}
                                      spitzenGespielt={spitzenGespielt}
                                      kontra={
                                        ansageSpieler2 === "kontra" ||
                                        ansageSpieler3 === "kontra" ||
                                        ansageSpieler2 === "bock" ||
                                        ansageSpieler3 === "bock" ||
                                        ansageSpieler2 === "jaeger" ||
                                        ansageSpieler3 === "jaeger"
                                      }
                                      re={
                                        ansageSpieler1 === "re" ||
                                        ansageSpieler1 === "hirsch" ||
                                        ansageSpieler1 === "meister"
                                      }
                                      bock={
                                        ansageSpieler2 === "bock" ||
                                        ansageSpieler3 === "bock" ||
                                        ansageSpieler2 === "jaeger" ||
                                        ansageSpieler3 === "jaeger"
                                      }
                                      hirsch={
                                        ansageSpieler1 === "hirsch" ||
                                        ansageSpieler1 === "meister"
                                      }
                                      jaeger={
                                        ansageSpieler2 === "jaeger" ||
                                        ansageSpieler3 === "jaeger"
                                      }
                                      meister={ansageSpieler1 === "meister"}
                                      bockrunde={bockRamschRunde === "bock"}
                                      verloren={gewonnen === "false"}
                                      spiel={spiel}
                                    />
                                  </>
                                )}
                              {spiel === "NULL" && (
                                <>
                                  <PunkteNull
                                    sendSpiel={sendNull}
                                    punkte={punkte}
                                    setPunkte={setPunkte}
                                    hand={hand}
                                    ouvert={ouvert}
                                    revolution={revolution}
                                    verloren={gewonnen === "false"}
                                    bockrunde={bockRamschRunde === "bock"}
                                  />
                                </>
                              )}
                              <Anmerkung anmerkung={anmerkung} />
                            </>
                          )}
                        </>
                      )}
                      {spiel === "RAMSCH" &&
                        spieler1 !== undefined &&
                        spieler2 !== undefined &&
                        spieler3 !== undefined && (
                          <>
                            <hr />
                            <Ramsch
                              sendSpiel={sendRamsch}
                              spieler1={spieler1}
                              spieler2={spieler2}
                              spieler3={spieler3}
                              bockrunde={bockRamschRunde === "bock"}
                            />
                          </>
                        )}
                    </>
                  )}
              </>
            </div>
          </ScrollPanel>
        </div>
      </div>
    </div>
  );
}

export default AddSpiel;
