import React, { useContext, useEffect, useState } from "react";
import "./Punkte.css";
import { ToastContext } from "../../../.././context/ToastContext";
import { Button } from "react-bootstrap";

export default function Punkte({
  mit,
  buben,
  hand,
  schneiderAngesagt,
  schneiderGespielt,
  schwarzAngesagt,
  schwarzGespielt,
  ouvert,
  spitzenAngesagt,
  spitzenGespielt,
  kontra,
  re,
  bock,
  hirsch,
  jaeger,
  meister,
  verloren,
  bockrunde,
  spiel,
  setPunkte,
  punkte,
  sendSpiel,
}) {
  const [prepared, setPrepared] = useState([]);
  const [multiplikator, setMultiplikator] = useState(0);
  const [grundwert, setGrundwert] = useState();
  const { showError } = useContext(ToastContext);

  useEffect(() => {
    let toPrepare = [];
    let multi = parseInt(buben);
    toPrepare.push({ name: mit ? "Mit" : "Ohne", multi: multi });
    multi += 1;
    toPrepare.push({ name: "Spiel", multi: multi });
    if (hand) {
      multi += 1;
      toPrepare.push({ name: "Hand", effekt: "(+1)", multi: multi });
    }
    if (schneiderAngesagt) {
      multi += 1;
      toPrepare.push({
        name: "Schneider angesagt",
        effekt: "(+1)",
        multi: multi,
      });
    }
    if (schneiderGespielt) {
      multi += 1;
      toPrepare.push({
        name: "Schneider gespielt",
        effekt: "(+1)",
        multi: multi,
      });
    }
    if (schwarzAngesagt) {
      multi += 1;
      toPrepare.push({
        name: "schwarz angesagt",
        effekt: "(+1)",
        multi: multi,
      });
    }
    if (schwarzGespielt) {
      multi += 1;
      toPrepare.push({
        name: "schwarz gespielt",
        effekt: "(+1)",
        multi: multi,
      });
    }
    if (ouvert) {
      multi += 1;
      toPrepare.push({ name: "Ouvert", effekt: "(+1)", multi: multi });
    }
    if (spitzenAngesagt) {
      multi += parseInt(spitzenAngesagt);
      toPrepare.push({
        name: spitzenAngesagt + " Spitzen angesagt",
        effekt: spitzenAngesagt + " x (+1)",
        multi: multi,
      });
    }
    if (spitzenGespielt) {
      multi += parseInt(spitzenGespielt);
      toPrepare.push({
        name: spitzenGespielt + " Spitzen gespielt",
        effekt: spitzenGespielt + " x (+1)",
        multi: multi,
      });
    }
    if (kontra) {
      multi = multi * 2;
      toPrepare.push({ name: "Kontra", effekt: "(x2)", multi: multi });
    }
    if (re) {
      multi = multi * 2;
      toPrepare.push({ name: "Re", effekt: "(x2)", multi: multi });
    }
    if (bock) {
      multi = multi * 2;
      toPrepare.push({ name: "Bock", effekt: "(x2)", multi: multi });
    }
    if (hirsch) {
      multi = multi * 2;
      toPrepare.push({ name: "Hirsch", effekt: "(x2)", multi: multi });
    }
    if (jaeger) {
      multi = multi * 2;
      toPrepare.push({ name: "Jäger", effekt: "(x2)", multi: multi });
    }
    if (meister) {
      multi = multi * 2;
      toPrepare.push({ name: "Meister", effekt: "(x2)", multi: multi });
    }
    if (verloren) {
      multi = multi * 2;
      toPrepare.push({ name: "Verloren", effekt: "(x2)", multi: multi });
    }
    if (bockrunde) {
      multi = multi * 2;
      toPrepare.push({ name: "Bockrunde", effekt: "(x2)", multi: multi });
    }
    toPrepare.sort((p1, p2) => p1.multi - p2.multi);
    setPrepared(toPrepare);
    setMultiplikator(multi);
  }, [
    mit,
    buben,
    hand,
    schneiderAngesagt,
    schneiderGespielt,
    schwarzAngesagt,
    schwarzGespielt,
    ouvert,
    spitzenAngesagt,
    spitzenGespielt,
    kontra,
    re,
    bock,
    hirsch,
    jaeger,
    meister,
    verloren,
    bockrunde,
  ]);

  useEffect(() => {
    switch (spiel) {
      case "GRAND":
        setGrundwert(24);
        break;
      case "KREUZ":
        setGrundwert(12);
        break;
      case "PIK":
        setGrundwert(11);
        break;
      case "HERZ":
        setGrundwert(10);
        break;
      case "KARO":
        setGrundwert(9);
        break;
      default:
        break;
    }
  }, [spiel]);

  useEffect(() => {
    setPunkte(grundwert * multiplikator);
  }, [grundwert, multiplikator]);

  const checkValid = () => {
    return true;
  };

  const onSubmit = () => {
    if (checkValid()) {
      sendSpiel();
    } else {
      showError("Die eingegeben Daten sind falsch!");
    }
  };

  return (
    <div className="punkte">
      <table>
        <thead>
          <tr>
            <th>Was</th>
            <th>Effekt</th>
            <th>Multiplikator</th>
            <th>Punkte</th>
          </tr>
        </thead>
        <tbody>
          {prepared.map((p) => (
            <tr>
              <td>{p.name}</td>
              <td>{p.effekt}</td>
              <td>{p.multi}</td>
            </tr>
          ))}
          <tr className="border-top">
            <td>Grundwert</td>
            <td>{grundwert}</td>
            <td>x {multiplikator}</td>
            <td>= {punkte}</td>
          </tr>
        </tbody>
      </table>
      <Button variant="dark" onClick={onSubmit}>
        hinzufügen
      </Button>
    </div>
  );
}
