import React, { useState } from "react";
import "./Phase10.css";

function Phase10() {
  const [currentPhase, setCurrentPhase] = useState(1);

  function Phase({ phase }) {
    let className = "phase";
    if (phase.number < currentPhase) {
      className = "checked " + className;
    } else if (phase.number === currentPhase) {
      className = "current " + className;
    }
    return (
      <div className={className}>
        <PhaseText phase={phase} />
      </div>
    );
  }

  function PhaseText({ phase }) {
    return (
      <div
        key={phase.id}
        onClick={(e) => {
          e.preventDefault();
          setCP(phase.number);
        }}
      >
        {phase.name}
      </div>
    );
  }

  function setCP(numberOfCP) {
    if (numberOfCP === currentPhase && numberOfCP !== 10) {
      setCurrentPhase(currentPhase + 1);
    } else {
      setCurrentPhase(numberOfCP);
    }
  }

  return (
    <div className="phase10">
      <h1>Phase 10</h1>
      {phases.map((phase) => (
        <Phase
          phase={phase}
          currentPhase={currentPhase}
          setCurrentPhase={setCurrentPhase}
        />
      ))}
    </div>
  );
}

export default Phase10;

const phases = [
  {
    number: 1,
    name: "2 Drillinge",
    id: "1",
  },
  {
    number: 2,
    name: "1 Drilling + 1 Viererfolge",
    id: "2",
  },
  {
    number: 3,
    name: "1 Vierling + 1 Viererfolge",
    id: "3",
  },
  {
    number: 4,
    name: "1 Siebenerfolge",
    id: "4",
  },
  {
    number: 5,
    name: "1 Achterfolge",
    id: "5",
  },
  {
    number: 6,
    name: "1 Neunerfolge",
    id: "6",
  },
  {
    number: 7,
    name: "2 Vierlinge",
    id: "7",
  },
  {
    number: 8,
    name: "7 Karten einer Farbe",
    id: "8",
  },
  {
    number: 9,
    name: "1 Fünfling + 1 Zwilling",
    id: "9",
  },
  {
    number: 10,
    name: "1 Fünfling + 1 Drilling",
    id: "10",
  },
];
