import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import Form from "../../components/form/Form";
import { AuthContext } from "../../context/AuthContext";
import { ToastContext } from "../../context/ToastContext";

function Login() {
  const { isAuth, loginUser, isLoggedIn } = useContext(AuthContext);
  const {showToast} = useContext(ToastContext);

  const submitLogin = async (formData) => {
    const data = await loginUser(formData);
    if (data.success && data.token) {
      localStorage.setItem("loginToken", data.token);
      await isLoggedIn();
    } else {
      showToast("Fehler!", data.message)
    }
  };

  if (isAuth) {
    return <Redirect to="/" />;
  }

  return (
    <div className="form">
      <h1>Login</h1>
      <Form
        handleSubmit={(formData) => submitLogin(formData)}
        fields={[
          {
            id: "1",
            label: "Email:",
            name: "email",
            type: "text",
          },
          {
            id: "2",
            label: "Password:",
            name: "password",
            type: "password",
          },
        ]}
      />
    </div>
  );
}

export default Login;
