import React, {useContext} from "react";
import '../App.css'
import { AuthContext } from "../context/AuthContext";
import Test from "reactandi"

function Startseite() {
  const {user} = useContext(AuthContext)
  const greeting = user ? <h1>Hallo, {user.usr_name}!</h1> : <div/>
  return (
    <div className="content">
      {greeting}
      <h1 style={{fontSize: "2em"}} >Wilkommen auf andreasjuergensen.de</h1>
      <p>
        Dies ist eine private Seite von Andreas Jürgensen. Es werden keinerlei
        komerzielle Zwecke verfolgt. Diese Seite ist ausschließlich für Familie
        und Freunde gedacht. Auf ein Impressum und eine Datenschutzerklärung wird daher
        verzichtet. Es werden keine (nicht technisch notwendige) Cookies verwendet. Bei Fragen melde dich an <u>webmaster@andreasjuergensen.de</u>.
      </p>
    </div>
  );
}

export default Startseite;
