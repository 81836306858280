import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../../context/ApiContext";
import axios from "axios";
import './Statistik.css';

export default function Statistik({ spieler, close, scoreboard }) {
  const { skatApi } = useContext(ApiContext);
  const [stats, setStats] = useState([]);
  const fetchStats = async () => {
    let statistics = [];
    await axios({
      method: "post",
      url: skatApi() + "statistics/get_statistics_for_scoreboard.php",
      headers: { "content-type": "application/json" },
      data: { scoreboardId: scoreboard },
    })
      .then((result) => {
        statistics = result.data.message;
      })
      .catch((error) => {
        console.log(error);
      });
    setStats(statistics.filter(s => spieler.includes(s.usr_name)));
  };

  useEffect(fetchStats, [spieler, skatApi]);
  return (
    <div className="statistik">
      <div className="close" style={{ marginTop: "-25px" }} onClick={close}>
        X
      </div>
      <table style={{ margin: "2px" }} className="table-striped">
        <thead>
          <tr>
            <td style={{ width: "" + 100 / (1 + spieler.length) + "%" }}>
              Kategorie
            </td>
            {stats.map((s, i) => (
              <td
                key={i}
                style={{ width: "" + 100 / (1 + stats.length) + "%" }}
              >
                {s.usr_name}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>gespielte Spiele</td>
            {stats.map((s, i) => (
              <td
                key={i}
                style={{ width: "" + 100 / (1 + stats.length) + "%" }}
              >
                {s.spiele_gespielt}
              </td>
            ))}
          </tr>
          <tr>
            <td>Punkte insgesamt</td>
            {stats.map((s, i) => (
              <td
                key={i}
                style={{ width: "" + 100 / (1 + stats.length) + "%" }}
              >
                {s.gesamt_punkte}
              </td>
            ))}
          </tr>
          <tr>
            <td>Punkte pro Spiel</td>
            {stats.map((s, i) => (
              <td
                key={i}
                style={{ width: "" + 100 / (1 + stats.length) + "%" }}
              >
                {(s.gesamt_punkte / s.spiele_gespielt).toFixed(2)}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
