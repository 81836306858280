import React, { useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { SkatContext } from "../../../context/SkatContext";

function AddScoreboard() {
  const [id, setId] = useState("");

  const { addScoreboard } = useContext(SkatContext);

  return (
    <div style={{ marginTop: "20px" }}>
      <Form.Group>
        <Form.Label style={{ textAlign: "left", width: "88%" }}>
          Scoreboard hinzufügen
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="scoreboard id"
          value={id}
          onChange={(e) => setId(e.target.value)}
          style={{ width: "80%", display: "inline" }}
        />
        <Button
          variant="dark"
          style={{ marginTop: "-5px", marginLeft: "5px" }}
          onClick={() => addScoreboard(id)}
        >
          Add
        </Button>
      </Form.Group>
    </div>
  );
}

export default AddScoreboard;
