import React, { createContext } from "react";

export const ApiContext = createContext();

function ApiContextProvider(props) {
  const getApiBasePath = () => {
    const domain = window.location.href.split("/")[2];
    if (domain.search("localhost") !== -1) {
      return "http://localhost/api/";
    } else {
      return "https://andreasjuergensen.de/api/";
    }
  };

  const getApiAuthPath = () => {
    return getApiBasePath() + "auth/";
  }

  const getApiAmarpPath = () => {
    return getApiBasePath() + "amrap/";
  }

  const getApiProgrammePath = () => {
    return getApiBasePath() + "programme/";
  }

  const getApiSkatPath = () => {
    return getApiBasePath() + "skat/";
  }

  return (
    <ApiContext.Provider
      value={{
        baseApi: getApiBasePath,
        authApi: getApiAuthPath,
        amrapApi: getApiAmarpPath,
        programmeApi: getApiProgrammePath,
        skatApi: getApiSkatPath,
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
}

export default ApiContextProvider;
