import React, { useState, useEffect } from "react";
import AJButtonGroup from "../../../../components/form/AJButtonGroup";
import AJTwoSelectButtonGroup from "../../../../components/form/AJTwoSelectButtonGroup";
import AJThreeSelectButtonGroup from "../../../../components/form/AJThreeSelectButtonGroup";

export default function Spieler({
  spieler1,
  setSpieler1,
  spieler2,
  setSpieler2,
  spieler3,
  setSpieler3,
  spieler,
  ramsch,
}) {
  const [spielerRadios, setSpielerRadios] = useState([]);
  const [
    spielerRadiosOhneEinzelspieler,
    setSpielerRadiosOhneEinzelspieler,
  ] = useState([]);

  useEffect(() => {
    const sR = [];
    spieler.forEach((spieler) => {
      sR.push({ name: spieler, value: spieler });
    });
    setSpielerRadios(sR);
  }, [spieler]);

  useEffect(() => {
    if (ramsch && spielerRadios.length === 3) {
      setSpieler1(spielerRadios[0].value);
      setSpieler2(spielerRadios[1].value);
      setSpieler3(spielerRadios[2].value);
    }
    const radiosOhneEinzelspieler = spielerRadios.filter(
      (spielerRadio) => spielerRadio.value !== spieler1
    );
    setSpielerRadiosOhneEinzelspieler(radiosOhneEinzelspieler);
    setSpieler2();
    setSpieler3();
    if (radiosOhneEinzelspieler.length === 2) {
      setSpieler2(radiosOhneEinzelspieler[0].value);
      setSpieler3(radiosOhneEinzelspieler[1].value);
    }
  }, [spielerRadios, spieler1]);
  if (ramsch) {
    return (
      <div className="eingabe">
        <h6 className="label">Spieler:</h6>
        <AJThreeSelectButtonGroup
          radioValueOne={spieler1}
          setRadioValueOne={setSpieler1}
          radioValueTwo={spieler2}
          setRadioValueTwo={setSpieler2}
          radioValueThree={spieler3}
          setRadioValueThree={setSpieler3}
          radios={spielerRadios}
        />
      </div>
    );
  }
  return (
    <div className="eingabe">
      <div className="eingabe">
        <h6 className="label">Einzelspieler:</h6>
        <AJButtonGroup
          radioValue={spieler1}
          setRadioValue={setSpieler1}
          radios={spielerRadios}
        />
      </div>
      {spieler1 && (
        <div className="eingabe">
          <h6 className="label">Gegenspieler:</h6>
          <AJTwoSelectButtonGroup
            radioValueOne={spieler2}
            setRadioValueOne={setSpieler2}
            radioValueTwo={spieler3}
            setRadioValueTwo={setSpieler3}
            radios={spielerRadiosOhneEinzelspieler}
          />
        </div>
      )}
    </div>
  );
}
