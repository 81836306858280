import React, { useEffect, useContext } from "react";
import "./Punkte.css";
import { ToastContext } from "../../../.././context/ToastContext";
import { Button } from "react-bootstrap";

export default function Punkte({
  hand,
  ouvert,
  revolution,
  verloren,
  bockrunde,
  setPunkte,
  punkte,
  sendSpiel,
}) {
  const { showError } = useContext(ToastContext);

  useEffect(() => {
    let p;
    if (revolution) p = 120;
    else if (hand && ouvert) p = 59;
    else if (ouvert) p = 46;
    else if (hand) p = 35;
    else p = 23;
    if (bockrunde) p = p * 2;
    if (verloren) p = p * 2;
    setPunkte(p);
  }, [revolution, hand, ouvert, verloren, bockrunde, setPunkte]);
  
  const checkValid = () => {
    return true;
  };
  const onSubmit = () => {
    if (checkValid()) {
      sendSpiel();
    } else {
      showError("Die eingegeben Daten sind falsch!");
    }
  };
  return (
    <div className="eingabe">
      Null {hand ? "Hand " : ""} {ouvert ? "Ouvert " : ""}
      {revolution ? "Revolution " : ""} {bockrunde ? "Bockrunde x2" : ""}{" "}
      {verloren ? "Verloren x2" : ""} = {punkte} <br></br>
      <Button variant="dark" onClick={onSubmit}>
        hinzufügen
      </Button>
    </div>
  );
}
