import React from "react";
import ToggleButton from "react-bootstrap/ToggleButton";

export default function AJButtonGroup({ style, value, setValue, name }) {
  return (
    <ToggleButton
      style={style}
      className="toggle-button"
      type="checkbox"
      variant="dark"
      name="radio"
      value="1"
      checked={value}
      onChange={(e) => setValue(e.currentTarget.checked)}
    >
      {name}
    </ToggleButton>
  );
}
