import React from "react";
import AJButtonGroup from "../../../../components/form/AJButtonGroup";

export default function Spitzen({
  spitzenAngesagt,
  setSpitzenAngesagt,
  spitzenGespielt,
  setSpitzenGespielt,
  grandGespielt,
}) {
  const radiosAngesagt = grandGespielt
    ? [
        { name: "keine", value: "" },
        { name: "1", value: "1" },
      ]
    : [
      { name: "keine", value: "" },
        { name: "1", value: "1" },
        { name: "2", value: "2" },
        { name: "3", value: "3" },
      ];
  const radiosGespielt = grandGespielt
    ? [
        { name: "keine", value: "" },
        { name: "1", value: "1" },
      ]
    : [
      { name: "keine", value: "" },
        { name: "1", value: "1" },
        { name: "2", value: "2" },
        { name: "3", value: "3" },
      ];
  return (
    <div className="eingabe">
      <h6>Spitzen:</h6>
      <div className="eingabe">
        <h6 className="label">Angesagt:</h6>
        <AJButtonGroup
          radios={radiosAngesagt}
          radioValue={spitzenAngesagt}
          setRadioValue={setSpitzenAngesagt}
        />
      </div>
      <div className="eingabe">
        <h6 className="label">Gespielt:</h6>
        <AJButtonGroup
          radios={radiosGespielt}
          radioValue={spitzenGespielt}
          setRadioValue={setSpitzenGespielt}
        />
      </div>
    </div>
  );
}
