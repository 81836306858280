import React, { useEffect, useState } from "react";
import AJButtonGroup from "../../../../components/form/AJButtonGroup";

export default function Ansagen({
  einzelspieler,
  ansageEinzelspieler,
  setAnsageEinzelspieler,
  gegenspieler1,
  ansageGegenspieler1,
  setAnsageGegenspieler1,
  gegenspieler2,
  ansageGegenspieler2,
  setAnsageGegenspieler2,
}) {
  const [radiosEinzelspieler, setRadiosEinzelspieler] = useState([]);
  const radiosGegenspieler = [
    { name: "Keine", value: "keine" },
    { name: "Kontra", value: "kontra" },
    { name: "Bock", value: "bock" },
    { name: "Jäger", value: "jaeger" },
  ];

  useEffect(() => {
    const radios = [];
    if (ansageGegenspieler1 === "jaeger" || ansageGegenspieler2 === "jaeger") {
      radios.push({ name: "Hirsch", value: "hirsch" });
      radios.push({ name: "Meister", value: "meister" });
    } else if (
      ansageGegenspieler1 === "bock" ||
      ansageGegenspieler2 === "bock"
    ) {
      radios.push({ name: "Re", value: "re" });
      radios.push({ name: "Hirsch", value: "hirsch" });
      if (ansageEinzelspieler === "meister") setAnsageEinzelspieler("");
    } else if (
      ansageGegenspieler1 === "kontra" ||
      ansageGegenspieler2 === "kontra"
    ) {
      radios.push({ name: "Keine", value: "keine" });
      radios.push({ name: "Re", value: "re" });
    } else {
      radios.push({ name: "Keine", value: "keine" });
    }
    setRadiosEinzelspieler(radios);
    if (!radios.map(r => r.value).includes(ansageEinzelspieler)) {
      setAnsageEinzelspieler(radios[0].value);
    }
  }, [ansageEinzelspieler, ansageGegenspieler1, ansageGegenspieler2]);

  return (
    <div className="eingabe">
      <h6>Ansagen:</h6>
      <div className="eingabe">
        <h6 className="label">
          {gegenspieler1 ? gegenspieler1 : "Gegenspieler1"}:
        </h6>
        <AJButtonGroup
          radios={radiosGegenspieler}
          radioValue={ansageGegenspieler1}
          setRadioValue={setAnsageGegenspieler1}
        />
      </div>
      <div className="eingabe">
        <h6 className="label">
          {gegenspieler2 ? gegenspieler2 : "Gegenspieler2"}:
        </h6>
        <AJButtonGroup
          radios={radiosGegenspieler}
          radioValue={ansageGegenspieler2}
          setRadioValue={setAnsageGegenspieler2}
        />
      </div>
      <div className="eingabe">
        <h6 className="label">
          {einzelspieler ? einzelspieler : "Einzelspieler"}:
        </h6>
        <AJButtonGroup
          radios={radiosEinzelspieler}
          radioValue={ansageEinzelspieler}
          setRadioValue={setAnsageEinzelspieler}
        />
      </div>
    </div>
  );
}
