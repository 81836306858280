import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Redirect } from "react-router-dom";

export default function NotFound() {
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  if (showLoading) {
    return (
      <Spinner
        animation="border"
        variant="secondary"
        style={{ marginTop: "20px" }}
      />
    );
  }
  return <Redirect to="/" />;
}
