import React from "react";
import "./App.css";

import AuthContextProvider from "./context/AuthContext";
import ApiContextProvider from "./context/ApiContext";
import ToastContextProvider from "./context/ToastContext";

import Home from "./Home";

function App() {
  return (
    <div className="App">
      <ToastContextProvider>
        <ApiContextProvider>
          <AuthContextProvider>
            <Home />
          </AuthContextProvider>
        </ApiContextProvider>
      </ToastContextProvider>
    </div>
  );
}

export default App;
