import React from "react";
import SkatContextProvider from "../../context/SkatContext";
import Scoreboards from "./Scoreboard/Scoreboards";

function Skat() {
  return (
    <SkatContextProvider>
      <h1>Skat</h1>
      <Scoreboards />
    </SkatContextProvider>
  );
}

export default Skat;
