import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { ApiContext } from "../../context/ApiContext";
import { ToastContext } from "../../context/ToastContext";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

function Programme(props) {
  const { user } = useContext(AuthContext);
  const { programmeApi } = useContext(ApiContext);
  const { showError, showSuccess } = useContext(ToastContext);

  const [programme, setProgramme] = useState([]);

  const fetchProgramme = async () => {
    axios({
      method: "post",
      url: programmeApi() + "get_programme.php",
      headers: { "content-type": "application/json" },
      data: {
        userId: user.usr_id,
      },
    })
      .then((result) => {
        console.log(result)
        if (result.data.success) {
          setProgramme(result.data.message);
        } else {
          showError(result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (user && user.usr_id) fetchProgramme();
  }, [user]);

  function addProgramm(id) {
    axios({
      method: "post",
      url: programmeApi() + "add_programm_to_user.php",
      headers: { "content-type": "application/json" },
      data: {
        userId: user.usr_id,
        programmId: id,
      },
    })
      .then((result) => {
        if (result.data.success) {
          showSuccess(result.data.message);
          fetchProgramme();
        } else {
          showError(result.data.message);
        }
        props.fetchP();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function removeProgramm(id) {
    axios({
      method: "post",
      url: programmeApi() + "remove_user_programm_by_id.php",
      headers: { "content-type": "application/json" },
      data: {
        upgId: id,
      },
    })
      .then((result) => {
        if (result.data.success) {
          showSuccess(result.data.message);
          fetchProgramme();
        } else {
          console.log(result.data);
          showError(result.data.message);
        }
        props.fetchP();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <h1>Programme</h1>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Hinzufügen/Entfernen</th>
          </tr>
        </thead>
        <tbody>
          {programme.map((p) => (
            <tr key={p.prm_id}>
              <td>{p.prm_id}</td>
              <td>{p.prm_name}</td>
              {p.upg_user ? (
                <td>
                  <Button
                    variant="danger"
                    style={{ lineHeight: "10px", paddingBottom: "8px" }}
                    onClick={() => removeProgramm(p.upg_id)}
                    disabled={!p.upg_user}
                  >
                    -
                  </Button>
                </td>
              ) : (
                <td>
                  <Button
                    variant="success"
                    style={{ lineHeight: "10px", paddingBottom: "8px" }}
                    onClick={() => addProgramm(p.prm_id)}
                    disabled={p.upg_user}
                  >
                    +
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default Programme;
