import React, { useContext, useEffect, useState } from "react";
import { SkatContext } from "../../../context/SkatContext";
import AddScoreboard from "./AddScoreboard";
import Button from "react-bootstrap/Button";
import Scoreboard from "./Scoreboard";

function Scoreboards() {
  const { fetchScoreboards } = useContext(SkatContext);
  const [scoreboards, setScoreboards] = useState([]);
  const [scoreboardId, setScoreboardId] = useState();
  const [scoreboardName, setScoreboardName] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    setScoreboardId(query.get("scoreboardId"));
  }, []);

  useEffect(() => {
    async function asyncFunction() {
      setScoreboards(await fetchScoreboards());
    }
    asyncFunction();
  }, [fetchScoreboards]);

  useEffect(() => {
    const sb = scoreboards.filter((s) => s.scb_id === scoreboardId)
    if(sb.length !== 0) setScoreboardName(sb[0].scb_name)
  }, [scoreboards, scoreboardId])

  function openScoreboard(id, name) {
    let params = new URLSearchParams(window.location.search);
    params.set("scoreboardId", id);
    window.location.search = params.toString();
  }

  if (scoreboardId) {
    return <Scoreboard id={scoreboardId} name={scoreboardName} />;
  }

  return (
    <div>
      <h2>Scoreboards</h2>
      <p>Deine Scoreboards:</p>
      {scoreboards.map((s) => (
        <div>
          <Button
            variant="outline-dark"
            style={{ width: "90%", marginBottom: "3px" }}
            onClick={() => openScoreboard(s.scb_id, s.scb_name)}
          >
            {s.scb_name}
          </Button>
        </div>
      ))}
      <AddScoreboard />
    </div>
  );
}

export default Scoreboards;
