import React from "react";

export default function Anmerkung({ anmerkung }) {
  if (anmerkung) {
    return (
      <div className="eingabe">
        <div className={"anmerkung anmerkung-dark"}>{anmerkung}!</div>
      </div>
    );
  } else {
    return <div />;
  }
}
