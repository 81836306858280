import React, { useContext } from "react";
import Form from "../../components/form/Form";
import { AuthContext } from "../../context/AuthContext";
import { ToastContext } from "../../context/ToastContext";

function Registrieren() {
  const { registerUser, loginUser, isLoggedIn } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);

  async function submitRegister(formData) {
    const data = await registerUser(formData);
    if (data.success) {
      const login = await loginUser({
        email: formData.email,
        password: formData.password,
      });
      localStorage.setItem("loginToken", login.token);
      await isLoggedIn();
    } else {
      console.log(data)
      showToast("Fehler!", data.message)
    }
  }

  return (
    <div className="form">
      <h1>Registrieren</h1>
      <Form
        autoCompleteOff={true}
        handleSubmit={(formData) => submitRegister(formData)}
        fields={[
          {
            id: "1",
            label: "Username:",
            name: "name",
            type: "text",
          },
          {
            id: "2",
            label: "E-Mail:",
            name: "email",
            type: "email",
          },
          {
            id: "3",
            label: "E-Mail bestätigen:",
            name: "c_email",
            type: "email",
          },
          {
            id: "4",
            label: "Password:",
            name: "password",
            type: "password",
          },
          {
            id: "5",
            label: "Password bestätigen:",
            name: "c_password",
            type: "password",
          },
        ]}
      />
    </div>
  );
}

export default Registrieren;
