import React from "react";
import AJButtonGroup from "../../../../components/form/AJButtonGroup";
import { ScrollPanel } from "primereact/scrollpanel";

export default function Spiel({ spiel, setSpiel }) {
  const radios = [
    { name: "Grand", value: "GRAND" },
    { name: "Kreuz", value: "KREUZ" },
    { name: "Pik", value: "PIK" },
    { name: "Herz", value: "HERZ" },
    { name: "Karo", value: "KARO" },
    { name: "Null", value: "NULL" },
    { name: "Ramsch", value: "RAMSCH" },
    { name: "Überreizt", value: "REIZ" },
  ];
  return (
    <>
      <div className="eingabe">
        <h6 className="label">Spiel:</h6>
        <ScrollPanel
          className="custom"
          style={{ height: "43px", width: "100%" }}
        >
          <AJButtonGroup
            radios={radios}
            radioValue={spiel}
            setRadioValue={setSpiel}
          />
        </ScrollPanel>
      </div>
    </>
  );
}
