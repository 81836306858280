import React from "react";
import "./ConfigAktiveSpieler.css";

export default function ConfigAktiveSpieler({
  spieler,
  aktiveSpieler,
  setAktiveSpieler,
  close
}) {
  const removeAktivenSpieler = (spieler) => {
    const newAktiveSpieler = [];
    aktiveSpieler.forEach((s) => {
      if (s !== spieler) newAktiveSpieler.push(s);
    });
    setAktiveSpieler(newAktiveSpieler);
  };

  const addAktivenSpieler = (spieler) => {
    if (!aktiveSpieler.includes(spieler)) {
      const newAktiveSpieler = [...aktiveSpieler];
      newAktiveSpieler.push(spieler);
      setAktiveSpieler(newAktiveSpieler);
    }
  };
  console.log(aktiveSpieler)
  return (
    <div style={{position: "relative"}}>
    <div className="config" onClick={() => close()}>X</div>
      <h4>Aktive Spieler:</h4>
      {aktiveSpieler.map((s, i) => (
        <div
          key={i}
          className="spieler"
          onClick={() => removeAktivenSpieler(s)}
        >
          {s} X
        </div>
      ))}
      <br></br>
      <br></br>
      <h4>Alle Spieler:</h4>
      {spieler.map((s, i) => (
        <div key={i} className="spieler" onClick={() => addAktivenSpieler(s)}>
          {s}
        </div>
      ))}
    </div>
  );
}
