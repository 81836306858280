import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

export default function AJTwoSelectButtonGroup({
  style,
  radios,
  radioValueOne,
  setRadioValueOne,
  radioValueTwo,
  setRadioValueTwo,
}) {
  const setValue = (value) => {
    if (value === radioValueOne) {
      const newOne = radioValueTwo;
      setRadioValueOne(newOne);
      setRadioValueTwo();
    } else if (value === radioValueTwo) {
      setRadioValueTwo();
    } else if (radioValueOne === undefined) {
      setRadioValueOne(value);
    } else if (radioValueTwo === undefined) {
      setRadioValueTwo(value);
    } else {
      const newOne = radioValueTwo;
      setRadioValueOne(newOne);
      setRadioValueTwo(value);
    }
  };
  return (
    <ButtonGroup toggle style={style}>
      {radios.map((radio, idx) => (
        <ToggleButton
          key={idx}
          type="radio"
          variant="dark"
          name="radio"
          value={radio.value}
          checked={
            radioValueOne === radio.value || radioValueTwo === radio.value
          }
          onClick={(e) => {
            // e.preventDefault();
            // e.target.value && console.log(e.target.value)
            e.target.value && setValue(e.target.value);
          }}
        >
          {radio.name}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
}
