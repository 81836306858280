import React from "react";
import AJToggleButton from "../../../../components/form/AJToggleButton";

export default function Gespielt({
  hand,
  setHand,
  schneiderAngesagt,
  setSchneiderAngesagt,
  schneiderGespielt,
  setSchneiderGespielt,
  schwarzAngesagt,
  setSchwarzAngesagt,
  schwarzGespielt,
  setSchwarzGespielt,
  ouvert,
  setOuvert,
  revolution,
  setRevolution,
  nullGespielt,
}) {
  return (
    <div className="eingabe">
      <h6 className="label">Gespielt:</h6>
      <AJToggleButton value={hand} setValue={setHand} name="Hand" />
      {!nullGespielt && (
        <>
          <AJToggleButton
            value={schneiderAngesagt}
            setValue={setSchneiderAngesagt}
            name="Schneider angesagt"
          />
          <AJToggleButton
            value={schneiderGespielt}
            setValue={setSchneiderGespielt}
            name="Schneider gespielt"
          />
          <AJToggleButton
            value={schwarzAngesagt}
            setValue={setSchwarzAngesagt}
            name="Schwarz angesagt"
          />
          <AJToggleButton
            value={schwarzGespielt}
            setValue={setSchwarzGespielt}
            name="Schwarz gespielt"
          />
        </>
      )}
      <AJToggleButton value={ouvert} setValue={setOuvert} name="Ouvert" />
      {nullGespielt && (
        <>
          <AJToggleButton
            value={revolution}
            setValue={setRevolution}
            name="Revolution"
          />
        </>
      )}
    </div>
  );
}
